.divid{
    width: 175px;
    height: 100%;
    background-color: red;
    margin-right: 10px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.blured{
    position:absolute;
    width: 100%;
    height: 30px;
    background-color: black;
    bottom: 0;
    /* filter: blur(5px); */
    opacity: 0.6;
    color: white;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}