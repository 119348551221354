.main{
    background: url("../../public/img/base3.png") no-repeat;
    overflow: hidden;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
}

.frame {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-15px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-15px); }
}

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex2{
    display: flex;
    margin-top: 30px;
}
.app-btn {
    width: 45% !important;
    max-width: 180px !important;
    color: #fff !important;
    /* margin: 20px 10px; */
    text-align: left !important;
    border-radius: 5px !important;
    text-decoration: none !important;
    font-family: "Lucida Grande", sans-serif !important;
    font-size: 10px !important;
    text-transform: uppercase !important;
}
.app-btn.blu {
    background-color: #101010 !important;
    transition: background-color 0.25s linear !important;
}
.app-btn.blu:hover {
    background-color: #454545 !important;
}
.app-btn i {
    width: 20% !important;
    text-align: center !important;
    font-size: 28px !important;
    margin-right: 7px !important;
}
.app-btn .big-txt {
    font-size: 17px !important;
    text-transform: capitalize !important;
}

@media only screen and (max-width: 1100px){
    .frame{
        margin-right: -40px;
        width: 294px !important;
       
    }
    .type{
        font-size: 2em !important;
    }
}
@media only screen and (max-width: 768px){
    .waveWrapper{
        display: none !important;
    }
    .main > div{
        flex-basis: 100%;
       
    }
    .frame{
        width: 350px !important;   
        margin-right: 0px !important; 
    }
    .main > div:first-child{
        margin-top: 160px;
        text-align: center;
    }
    .fr{
        text-align: center;
    }
    .flex2{
        align-items: center;
        justify-content: center;
    }
    .main{
        /* background: none; */
        background: url("../../public/img/back2.png") no-repeat;
        overflow: hidden;
        background-size: cover;
    }

}

.textOnBtn{
    margin-top:revert;
    margin-bottom: revert;
}