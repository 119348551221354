@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

#features {
  background: linear-gradient(rgba(30, 149, 155, 0.7), rgba(243, 168, 35, 0.7)), url(https://source.unsplash.com/random);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 3rem 0; 
    margin-top: 15px;
/* 
  -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.6); */



}

.features-inner {
  padding: 2rem; }
  @media (min-width: 600px) {
    .features-inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 0; } }
  @media (min-width: 800px) {
    .features-inner {
      grid-template-columns: 1fr 2fr; } }
  @media (min-width: 900px) {
    .features-inner {
      grid-template-columns: repeat(3, 1fr);
      max-width: 1000px;
      margin: 0 auto; } }

.features-image {
  text-align: center;
  margin-bottom: 2rem; }
  @media (min-width: 600px) {
    .features-image {
      grid-row: 1 / span 2; } }
  @media (min-width: 900px) {
    .features-image {
      grid-column: 2; } }
  .features-image img {
    filter: drop-shadow(1px 1px 20px rgba(0, 0, 0, 0.4)); }

.features-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.features-item {
  transition: opacity 200ms ease; }
  .features-item:hover {
    /* opacity: .7; */
    /* cursor: pointer; */
   }

.features-item-header,
.features-item-text {
  color: white; }

.features-item-header {
  margin-bottom: 0;
  font-size: 1.4rem; }

.features-item-text {
  margin-top: .3rem; }

@media (min-width: 900px) {
  .list-1 {
    text-align: right; } }

@media (min-width: 600px) {
  .list-2 {
    grid-column: 2; }
    .list-2 .features-item-header:first-child {
      margin-top: 0; } }

@media (min-width: 900px) {
  .list-2 {
    grid-column: 3; }
    .list-2 .features-item-header:first-child {
      margin-top: 1.45rem; } }
