.changeables{
    display: flex;
    flex-wrap: wrap;
    margin-top: -10px;
}
.changeables>div{
    background-color: red;
    padding: 7px;
    border-radius: 5px;
    background-color: #F5F5F9;
    margin-right: 10px;
    margin-top: 10px;
}
.bx-sm{
    font-size: 20px !important;
}