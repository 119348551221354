.navbarNav{
    display: flex !important;
    height: 100px !important;
    position:absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    /* background-color: aqua; */
    justify-content: space-around;
    padding-top: 20px !important;
    align-items: center !important;
}
.linkNav{
    /* width: 100px; */
    height: 50px !important;
    /* border: 1px solid red; */
    text-align: center !important;
    line-height: 50px !important;
    margin-left: 50px !important;
    border-radius: 10px !important;
    cursor: pointer ;
    font-weight: bold !important;
    text-decoration: none !important;
    color: black !important;
}

@media only screen and (max-width: 1100px){
    .logo{
        margin-left: -20px !important;
    }
}
@media only screen and (max-width: 768px){
    .logo{
        width: 80px !important;
        height: 80px !important;
        margin-left: 15px !important;
    }
    .links{
        display: none !important;
    }
    .hideNav{
        display: block !important;
        margin-right: 20px !important;
    }
    .navbarNav{
        justify-content: space-between ;
    }
}

@media only screen and (min-width: 768px){
    .onverlayNav{
        display: none !important;
    }
}


.link2{
    width: 100%;
    height: 50px;
    /* border: 1px solid red; */
    text-align: center;
    line-height: 50px;
    /* margin-left: 50px; */
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    color: black;
}