details {
    margin: 10px auto ;
    /* background: #282828; */
    margin-bottom: .5rem;
    box-shadow: 0 .1rem 1rem -.5rem rgba(0,0,0,.4);
    border-radius: 5px;
    overflow: hidden;
    width: 90%;
    box-shadow: 0 0px 0px rgba(0,0,0,0.30), 0 15px 19px rgba(0,0,0,0.22);

  }
  
  summary {
    padding: 1rem;
    display: block;
    /* background: #333; */
    padding-left: 2.2rem;
    position: relative;
    cursor: pointer;
  }
  
  summary:before {
    content: '';
    border-width: .4rem;
    border-style: solid;
    border-color: transparent transparent transparent rgb(0, 0, 0);
    position: absolute;
    top: 1.3rem;
    left: 1rem;
    transform: rotate(0);
    transform-origin: .2rem 50%;
    transition: .25s transform ease;
  }
  
  /* THE MAGIC 🧙‍♀️ */
  details[open] > summary:before {
    transform: rotate(90deg);
  }
  
  details summary::-webkit-details-marker {
    display:none;
  }
  
  details > ul {
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
  
  .details-expanded {
      padding: 0 2rem;
  }
  
  /* body {
    background: #222;
    height: 100vh;
    font-family: sans-serif;
    color: white;
    line-height: 1.5;
    letter-spacing: 1px;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 2rem;
  } */
  pre {
      white-space: pre-wrap;
      word-break: break-all;
  }