.bx-sm{
    font-size: 15px !important;
}

.lists{
    width: 100%;
    height: 200px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: -webkit-box;
}