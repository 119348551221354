#app { height: 100% }

.swiper {
  width: 80%;
  padding-top: 50px;
  padding-bottom: 50px;
}
@media only screen and (max-width: 780px){
  .swiper {
    width: 100% !important;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  /* height: 300px; */
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-pagination-bullet-active{
  background-color: #F3A722;
  width: 30px;
  border-radius: 5px;
}
