.footerbg{
  width: 100%;
  position: absolute;
  margin-top: 30px;
  z-index: 0;
  bottom: 0;
}
.footerbg > img {
  width: 100%;
  min-height: 500px;
}
.footer{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-end;
  margin-top: 150px;
}
/* .footer-col:first-child{
  flex-basis: 40%;

} */
.footer-col{
 /* background-color: red; */
  z-index: 1;
  flex-basis: 20%;
  height: 380px;
  margin: 10;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

}
.about{
  font-size: 17px;
  margin-top: 0px !important;
  text-align: justify;
  margin-right: -80px;
}


/* .flex {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */
.flex3{
  display: flex !important;
  margin-top: 30px !important;
  flex-direction: column !important;
  width: 100% !important;
}
.app-btn1 {
  width: 100% !important;
  max-width: 280px !important;
  color: #fff !important;
  /* margin: 20px 10px; */
  text-align: left !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  font-family: "Lucida Grande", sans-serif !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
}
.app-btn1.blu {
  background-color: #101010 !important;
  transition: background-color 0.25s linear !important;
}
.app-btn1.blu:hover {
  background-color: #454545 !important;
}
.app-btn1 i {
  width: 20% !important;
  text-align: center !important;
  font-size: 28px !important;
  margin-right: 7px !important;
}
.app-btn1 .big-txt {
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.link3{
  /* width: 100px; */
  height: 50px;
  /* border: 1px solid red; */
  text-align: center;
  line-height: 50px;
  margin-left: 50px;
  border-radius: 10px;
  cursor: pointer;
  /* font-weight: bold; */
  text-decoration: none;
  color: black !important;
  justify-content: center;
  align-items: center;
  display: flex;
}


.seperate{
  position:absolute;
  width: 100%;
  /* padding: 30px; */
  text-align: center;
  z-index: 4;
  bottom: 0;
}

@media only screen and (max-width: 1000px){
  .footer{
    align-items: center;
    background-color: #EFF2F5 !important;
    padding-top: 75px !important
  }
  .footer-col{
    flex-basis: 50%;
    margin-top: -40px;
    height: 340px !important;
  }
  .lg-footer{
    margin-left: 50px!important;
  }
  .about{
    margin-right: 0px;
  }
  #dortana{
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 600px){
 
  .footer-col{
    flex-basis: 100%;
    margin-top: -70px;
  }
  .about{
    margin-right: 40px;
  }
  .flex3{
    align-items: center;
  }
}