#steps {
      /* background: linear-gradient(rgba(30, 149, 155, 0.7), rgba(243, 168, 35, 0.7)), url(https://source.unsplash.com/random); */
      background-color: #EFF2F5;
      /* background-repeat: no-repeat; */
      /* background-size: cover; */
      /* background-position: center; */
      padding: 3rem 0; 
}

.cardHolder{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    margin: 60px auto;
}
.cardHolder > div{
    background-color: #fff;
    height: 400px;
    border-radius: 10px;
    animation: slide 1s forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
}
.cardHolder > div > img{
   width: 130px;
   height: 130px;
   
}
@keyframes slide {
    from {opacity:0;}
    to {opacity: 1;}
}

.sep{
    border-top: 2px solid #F3A824;
    width: 40px;
    margin-top: 20px;
}
.cardHolder > div:hover .sep{
    animation: widt 1s forwards;
}
.cardHolder > div:hover .title{
    color: #F3A824;
}
.title{
   color: rgb(54, 50, 74);
   font-size: 1.5em;
   margin-bottom: 15px;
   margin-top: 10px;
}
@keyframes widt {
    from {width:40px;}
    to {width: 100px;}
}

.cardHolder > div:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1000px){
    .cardHolder{
        grid-template-columns: repeat(3,1fr);
    }
}
@media only screen and (max-width: 768px){
    .cardHolder{
        grid-template-columns: repeat(2,1fr);
    }
}
@media only screen and (max-width: 420px){
    .cardHolder{
        grid-template-columns: repeat(1,1fr);
    }
}